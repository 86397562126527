import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CashService } from '../modules/cash/cash.service';

export const cashGuard: CanActivateFn = (route, state) => {
  const cashService = inject(CashService);
  const isCash = cashService.cashOpened;
  const router = inject(Router);

  if (!isCash) {
    router.navigate(['/auth/abrir-caixa']);
    return false;
  }

  return true;
};
