import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';

export const branchGuard: CanActivateFn = (route, state) => {

  const authService = inject(AuthService);
  const isbranched = authService.branch;
  const router = inject(Router);

  if (!isbranched) {
    router.navigate(['auth/escolha-unidade']);
    return false;
  }

  return true;
};
