import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitlerComponent } from "../../components/titler/titler.component";

@Component({
  selector: 'app-errors',
  standalone: true,
  imports: [TitlerComponent],
  templateUrl: './errors.component.html',
  styleUrl: './errors.component.scss'
})
export class ErrorsComponent {

  errorCode: number | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.errorCode = +params.get('code')!;
    });
  }

}
