import { ApplicationConfig, provideZoneChangeDetection, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './interceptors/auth-interceptor';
import { LocationStrategy, HashLocationStrategy, CurrencyPipe } from '@angular/common';
import { provideServiceWorker } from '@angular/service-worker';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { BranchPipe } from './pipes/branch.pipe';
import { MePipe } from './pipes/me.pipe';

export const appConfig: ApplicationConfig = {
  providers: [
    CnpjPipe,
    BranchPipe,
    MePipe,
    CurrencyPipe,
    provideHttpClient( withInterceptors([authInterceptor]),),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    { provide: LocationStrategy, useClass: HashLocationStrategy }, provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          })
  ],
};
