import { AuthService } from './modules/auth/auth.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { VersionService } from './services/version.service';
import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageInstallPwaComponent } from './components/message-install-pwa/message-install-pwa.component';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MessageInstallPwaComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  constructor(private authService: AuthService, private updates: SwUpdate,  private versionService: VersionService) {}

  ngOnInit(): void {
    this.authService.startTokenRefreshCountdown();


    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        if (event.type === 'VERSION_READY') {
          this.promptUserForUpdate();
        }
      });
    }
  }

  promptUserForUpdate(): void {
    const userConfirmed = window.confirm(`Nova versão disponível! Deseja atualizar agora? Versão atual: (${this.versionService.get})`);

    if (userConfirmed) {
      this.updates.activateUpdate().then(() => document.location.reload());
    }
  }


}
