import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BtnComponent } from '../../components/btn/btn.component';
import { IconComponent } from '../../components/icon/icon.component';

@Component({
  selector: 'app-dev-test',
  templateUrl: './dev-test.component.html',
  standalone:true,
  imports:[CommonModule, BtnComponent, IconComponent]
})
export class DevTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
