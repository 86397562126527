<max-area-content>

  <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2">
    <div class="font-bold text-xl">Tutoriais</div>
    <div class="text-sm lg:text-md">Instruções de uso da aplicação</div>
  </div>


  <div class="grid grid-cols-1 md:grid-cols-3 gap-5">

    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2 h-[300px]">
      <div class="font-bold text-lg mb-3">
        <small class="opacity-55">(V2.2.5) </small><br> Entrada de Veículos e exemplo do status pendente de estacionamento
      </div>
      <iframe width="100%"  height="100%" src="https://www.youtube.com/embed/aI3rsdPTlN0" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2 h-[300px]">
      <div class="font-bold text-lg mb-3">
        <small class="opacity-55">(V2.2.5) </small><br> Simulação de saída de veículos
      </div>
      <iframe width="100%"  height="100%" src="https://www.youtube.com/embed/HTluhxvqNiU" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2 h-[300px]">
      <div class="font-bold text-lg mb-3">
        <small class="opacity-55">(V2.2.5) </small><br> Controle de Pátio
      </div>
      <iframe width="100%"  height="100%" src="https://www.youtube.com/embed/oh_8gc73L60" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2 h-[300px]">
      <div class="font-bold text-lg mb-3">
        <small class="opacity-55">(V2.2.6) </small><br> Ciclo completo Todas as etapas do fluxo
      </div>
      <iframe width="100%"  height="100%" src="https://www.youtube.com/embed/ZUdMf355vh4" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

  </div>

</max-area-content>
