import { Component, OnInit } from '@angular/core';
import { MaxAreaContentComponent } from "../../components/max-area-content/max-area-content.component";

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css'],
  standalone:true,
  imports: [MaxAreaContentComponent]
})
export class TutorialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
