import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-install-pwa',
  templateUrl: './message-install-pwa.component.html',
  imports:[CommonModule],
  standalone:true,
})
export class MessageInstallPwaComponent implements OnInit {
  deferredPrompt: any;
  showButton = false;

  constructor() {}

  ngOnInit() {
    this.checkIfInstalled();

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showButton = true;
    });

    window.addEventListener('appinstalled', () => {
      console.log('PWA foi instalada');
      this.showButton = false;
    });
  }

  installPwa(): void {
    // Mostra o prompt de instalação
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuário aceitou a instalação');
        } else {
          console.log('Usuário recusou a instalação');
        }
        this.deferredPrompt = null;
        this.showButton = false;
      });
    }
  }

  checkIfInstalled(): void {
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
    if (isInStandaloneMode) {
      this.showButton = false;
    }
  }

}
