<main class="container grid grid-cols-1 lg:grid-cols-4 gap-8 p-8">


  <div class="p-5 bg-white rounded-md md:rounded-xl shadow w-full lg:w-56 grid gap-5">
    <div>Componente Btn </div>

    <div class="w-8">
      <Btn [loading]='false' styler="primary">
        <div class="mt-1">
          <Icon src="options" class="text-xl" />
        </div>
      </Btn>
    </div>

    <Btn [loading]='false'>
      <div>
        <Icon src="barrier" class="text-xl" /> Default
      </div>
    </Btn>

    <Btn [loading]='false' styler="primary">
      <div class="">
        <Icon src="car-pending" class="text-xl" /> Primary
      </div>
    </Btn>

    <Btn [loading]='false' styler="secondary">
      <div class="">
        <Icon src="school" class="text-xl" /> Secondary
      </div>
    </Btn>

    <Btn [loading]='false' styler="success">
      <div class="">
        <Icon src="car-pending" class="text-xl" /> Success
      </div>
    </Btn>

    <Btn [loading]='false' styler="warning">
      <div class="">
        <Icon src="car-pending" class="text-xl" /> Warning
      </div>
    </Btn>

    <Btn [loading]='false' styler="info">
      <div class="">
        <Icon src="car-breakdown" class="text-xl" /> Info
      </div>
    </Btn>

    <Btn [loading]='false' styler="danger">
      <div class="">
        <Icon src="code" class="text-xl" /> Danger
      </div>
    </Btn>

  </div>


  <div class="h-52 w-full">
    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2">
      <div class="font-bold text-lg">Reservas</div>
      <div class="text-sm lg:text-md">Exibindo reservas desde 06/10/2024</div>
    </div>

    <div class="bg-white rounded-md md:rounded-xl p-4 flex flex-col items-start justify-center m-2">
      <div class="font-bold text-lg">Pátio (294)</div>
      <div class="text-sm lg:text-md">Exibindo 294 de 294</div>
    </div>
  </div>

</main>
