import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';

export const loginGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const isAuthenticated = authService.isAuthenticated;
  const router = inject(Router);

  if (isAuthenticated && state.url.endsWith("/login")) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
