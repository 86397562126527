import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const userRoles = authService.roles;
  const router = inject(Router);
  const expectedRoles = route.data['roles'] as Array<string>;

  const hasRole = userRoles.some((role: string) => expectedRoles.includes(role));

  if (!hasRole) {
    router.navigate(['erro/401']);
    return false;
  }

  return true;
};
