<div class="flex flex-row justify-center w-full mt-24">


@if(errorCode == 401){

  <div class="flex flex-col items-center  w-full">
    <div class="text-7xl font-bold text-yellow-600">Erro 401</div>
    <Titler title="Sem permissão de acesso"/>
    <div class="text-lg">Você não tem permissão de acesso a esse recurso!</div>
  </div>
}


@if(errorCode == 404){

  <div class="flex flex-col items-center  w-full">
    <div class="text-7xl font-bold text-yellow-600">Erro 404</div>
    <Titler title="Página não encontrada"/>
    <div class="text-lg">Esta página não existe ou está indisponível!</div>
  </div>
}

@if(errorCode == 500){

  <div class="flex flex-col items-center  w-full">
    <div class="text-7xl font-bold text-yellow-600">Erro 500</div>
    <Titler title="Erro desconhecido"/>
    <div class="text-lg">Um erro inesperado ocorreu. Caso persista contate suporte!</div>
  </div>
}


</div>
