import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';

export const resetPassGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const isAuthenticated = authService.isAuthenticated;
  const router = inject(Router);


  if(authService.me.reset_password === 1){
  console.log(authService.me.reset_password)

    router.navigate(['/auth/reset-password/', authService.me.uuid]);
    return false;
  }

  return true;
};
